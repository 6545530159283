<template>
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card v-if="taskGroupData">
			<v-card-title>{{ isUpdate ? 'Edit' : 'Add' }} Task Group</v-card-title>
			<v-card-text>

				<v-text-field v-model="taskGroupData.name" label="Name" class="mt-4" dense outlined hide-details required></v-text-field>

				<v-radio-group v-model="taskGroupData.type" :disabled="isUpdate" dense>
					<template v-slot:label>
						<div>Task Group Type (cannot be changed later):</div>
					</template>
					<v-radio v-for="i in getEnumValues('TaskGroupTypeEnum')" :key="i.value" :label="i.text" :value="i.value" :disabled="i.value == 'kanban'">
						<template v-slot:label>
							<div>
								<v-icon left right>{{$insight.helpers.getTaskGroupIcon(i.value)}}</v-icon>
								{{i.text}} <em v-if="i.value == 'kanban'">coming soon &trade;</em>
							</div>
						</template>
					</v-radio>
				</v-radio-group>

				<v-menu v-if="taskGroupData.type == 'waterfall'" v-model="calendarMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y ref="calendarMenu" min-width="200px">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-model="taskGroupData.start_date" type="date" label="Start Date" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" hide-details outlined dense class="mt-6"></v-text-field>
					</template>
					<v-date-picker
						v-model="taskGroupData.start_date"
						@input="calendarMenu = false"
						header-color="primary"
						color="primary"
						show-adjacent-months
						no-title
						:allowed-dates="allowedDates"
					></v-date-picker>
				</v-menu>

				<v-row>
					<v-col cols="12" sm="6">
						<v-switch v-model="taskGroupData.is_archived" label="Archive" hint="Hide's this task group and prevents users from adding tasks to it" persistent-hint class="mb-6"></v-switch>
					</v-col>
					<v-col cols="12" sm="6">
						<v-switch v-model="taskGroupData.is_default" :disabled="isUpdate && taskGroup && taskGroup.is_default" label="Default"></v-switch>
					</v-col>
				</v-row>

			</v-card-text>

			<v-card-actions>
				<div class="ml-auto">
					<v-btn @click="modal.trigger('close')" :disabled="saving" class="mr-3">Close</v-btn>
					<v-btn color="primary" @click="submit" :loading="saving">{{ isUpdate ? 'Update' : 'Create' }}</v-btn>
				</div>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import moment from "moment";
	import { IdType } from "../../utils/IdType";

	export default {
		props: {
			modal: {
				type: Object,
				required: true,
			},
			taskGroupId: {
				type: IdType,
				default: 0,
			},
			projectId: {
				type: IdType
			},
		},

		apollo: {
			taskGroup: {
				query: gql`
					query taskTreeTaskGroups($id: ID!) {
						taskGroup(id: $id) {
							uid
							id
							name
							type
							is_archived
							is_default
							start_date @formatDate(format: "Y-m-d")
						}
					}
				`,
				variables() {
					return {
						id: this.taskGroupId,
					};
				},
				skip() {
					return !this.taskGroupId;
				},
			},
			// project: {
			// 	query: gql`
			// 		query GetProjectStarted($id: ID!) {
			// 			project(id: $id) {
			// 				id
			// 				project_started @formatDate(format: "Y-m-d")
			// 				creation_date @formatDate(format: "Y-m-d")
			// 			}
			// 		}
			// 	`,
			// 	variables() {
			// 		return {
			// 			id: this.projectId
			// 		};
			// 	},
			// 	skip() {
			// 		return !!this.taskGroupId;
			// 	}
			// }
		},

		data() {
			return {
				saving: false,
				taskGroupData: null,
				calendarMenu: false,
			};
		},

		computed: {
			isUpdate() {
				return !!this.taskGroupId;
			},
		},

		methods: {
			submit() {
				this.saving = true;

				if (this.isUpdate) {
					this.$apollo
						.mutate({
							mutation: gql`
								mutation($data: UpdateTaskGroupArgs!) {
									updateTaskGroup(data: $data) {
										id
									}
								}
							`,
							variables: {
								data: this.formModel()
							},
							update: (store, { data: { updateTaskGroup } }) => {
								this.modal.trigger("save close");
							}
						})
						.catch((error) => {
							console.error("error updating task group", error);
							this.modalErrorMessage = "There was a problem updating the data.";
						})
						.then(() => this.saving = false);
				} else {
					this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: CreateTaskGroupArgs!) {
									createTaskGroup(data: $data) {
										id
									}
								}
							`,
							variables: {
								data: this.formModel()
							},
							update: (store, { data: { createTaskGroup } }) => {
								this.modal.trigger("save close", createTaskGroup)
							}
						})
						.catch((error) => {
							console.error("error adding task group", error);
							this.modalErrorMessage = "There was a problem creating the task group.";
						})
						.then(() => this.saving = false);
				}
			},

			copyData(graphQlData = {}) {
				let copyManyFn = (props, subKey = "") => {
					let source = graphQlData;
					if (subKey) {
						source = source[subKey] || {};
					}

					let result = {};
					for (let i of props) {
						result[i] = source.hasOwnProperty(i) ? source[i] : null;
					}

					return result;
				};

				this.taskGroupData = {
					...copyManyFn([
						"name",
						"type",
						"is_archived",
						"is_default",
						"start_date",
					]),
				};
			},

			formModel() {
				let model = {
					name: this.taskGroupData.name,
					// type: this.taskGroupData.type,
					is_default: !!this.taskGroupData.is_default,
					is_archived: !!this.taskGroupData.is_archived,
					start_date: this.taskGroupData.start_date,
				};

				if (this.isUpdate) {
					model.id = this.taskGroupId;
				}
				else {
					model.project_id = this.projectId;
					model.type = this.taskGroupData.type;

				}

				return model;
			},

			allowedDates(v) {
				let day = moment(v).day();
				return day >= 1 && day <= 5;
			}
		},

		watch: {
			taskGroup(to) {
				this.copyData(to);
			},
			// project(to) {
			// 	this.taskGroupData.start_date = to.project_started || to.creation_date;
			// },
			taskGroupId: {
				immediate: true,
				handler(to) {
					if (!to) {
						this.copyData({
							type: 'waterfall'
						});
					}
				},
			},
			projectId: {
				immediate: true,
				handler(to) {
					if (!this.isUpdate && !to) {
						console.error('TaskGroupAddEdit: projectId is needed for new taskGroup');
					}
				}
			}
		},
	};
</script>